import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0, // Không lưu cache
      staleTime: Infinity, // Dữ liệu luôn fresh, không refetch tự động
      refetchOnWindowFocus: false, // Không refetch khi focus lại tab
      refetchOnReconnect: false, // Không refetch khi mạng reconnect
      refetchOnMount: 'always',
    },
  },
});

import { LoadingButton } from '@mui/lab';
import { Checkbox, Container, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useMutation } from '@tanstack/react-query';
import { TextFieldNumber, TextFieldSelect } from 'components/common';
import { DateTime } from 'luxon';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { courseService, queryClient } from 'services';
import { CourseMethodTypes } from 'utils/common';
import { TextEditor } from 'views/Post';

type Props = {
  course: Course;
};

const CourseUpdate = ({ course }: Props) => {
  const { control, reset, handleSubmit } = useForm<CourseUpdateBody>();

  const {
    mutate: updateCourse,
    isLoading: isLoadingUpdate,
    variables: payload,
  } = useMutation(courseService.updateCourse, {
    onSuccess: () => {
      if (payload?.isDaft) {
        enqueueSnackbar('Lưu nháp khóa học thành công');
      } else {
        enqueueSnackbar('Xuất bản khóa học thành công');
      }
      queryClient.invalidateQueries(['courseService.listCourses']);
    },
  });

  useEffect(() => {
    reset({
      code: course.code,
      name: course.name,
      imageUrl: course.imageUrl,
      method: course.method ?? 'ONLINE',
      suitableObject: course.suitableObject,
      content: course.content,
      lecturer: course.lecturer,
      startEnrollmentDate: course.startEnrollmentDate ? DateTime.fromISO(course.startEnrollmentDate) : null,
      fee: course.fee,
      timeInHour: course.timeInHour,
      timeInLesson: course.timeInLesson,
      isPasstestToView: course.isPasstestToView === 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateCourse({
        ...values,
        id: course.id,
        isDaft: false,
      });
    })();
  };

  const handleClickSubmitDraft = () => {
    handleSubmit((values) => {
      updateCourse({
        ...values,
        id: course.id,
        isDaft: true,
      });
    })();
  };
  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
              <span>Cập nhật khóa học</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Tên khóa học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Tên khóa học'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name='code'
              control={control}
              rules={{
                required: 'Mã khóa học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Mã khóa học'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name='method'
              defaultValue={course.method ?? 'ONLINE'}
              control={control}
              rules={{
                required: 'Hình thức học là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  required
                  fullWidth
                  label='Hình thức học'
                  error={!!error}
                  helperText={error?.message}
                >
                  {CourseMethodTypes.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Controller
              name='startEnrollmentDate'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  format='dd/MM/yyyy'
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      label: 'Ngày khai giảng',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name='timeInLesson'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldNumber {...field} fullWidth label='Số bài' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name='timeInHour'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldNumber
                  {...field}
                  fullWidth
                  label='Thời lượng học (giờ)'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name='fee'
              control={control}
              rules={{
                required: 'Học phí là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldNumber
                  {...field}
                  fullWidth
                  required
                  label='Học phí (vnđ)'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name='isPasstestToView'
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControlLabel label='Cần pass bài test' control={<Checkbox {...field} color='primary' />} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' color='info' className='mb-2'>
              <span>Mô tả ngắn</span>
            </Typography>
            <Controller
              name='shortDescription'
              control={control}
              render={({ field }) => <TextEditor data={field.value} onChange={field.onChange} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' color='info' className='mb-2'>
              <span>Thông tin giảng viên</span>
            </Typography>
            <Controller
              name='lecturer'
              control={control}
              render={({ field }) => <TextEditor data={field.value} onChange={field.onChange} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' color='info' className='mb-2'>
              <span>Nội dung học</span>
            </Typography>
            <Controller
              name='content'
              control={control}
              render={({ field }) => <TextEditor data={field.value} onChange={field.onChange} />}
            />
          </Grid>
          <Grid item xs={12}>
            <div className='flex justify-items-center space-x-3'>
              <LoadingButton
                variant='outlined'
                color='warning'
                loading={isLoadingUpdate && payload?.isDaft}
                onClick={handleClickSubmitDraft}
              >
                Lưu nháp
              </LoadingButton>
              <LoadingButton
                variant='contained'
                color='primary'
                loading={isLoadingUpdate && !payload?.isDaft}
                onClick={handleClickSubmit}
              >
                Xuất bản
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CourseUpdate;

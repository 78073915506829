import { Logout, Menu, Person } from '@mui/icons-material';
import { AppBar, Avatar, Button, Chip, Drawer, IconButton, Toolbar } from '@mui/material';
import { AppMenu, AuthMenu } from 'containers';
import { onSnapshot } from 'firebase/firestore';
import { useWindowSize } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileSelector, signOut } from 'reducers/profileSlice';
import { authRoute, privateRoute, publicRoute } from 'routes';
import { firestoreService, authService } from 'services';

type Props = {
  openDrawer?: boolean;
  onDrawerChange: (open: boolean) => void;
};

const AppHeader = ({ openDrawer, onDrawerChange }: Props) => {
  const dispatch = useDispatch();

  const { isDesktop, isMobile } = useWindowSize();
  const { isLoggedIn, fullName, id, listRoles } = useSelector(profileSelector);

  const canOpenDrawer = isLoggedIn || isMobile;

  useEffect(() => {
    if (!isLoggedIn || !id || (listRoles ?? []).includes('ADMIN')) {
      return;
    }
    const doc = firestoreService.getDoc(
      process.env.REACT_APP_FIREBASE_AUTH_SESSION_ROOT ?? 'auth_sessions',
      `user_${id}`,
    );
    onSnapshot(
      doc,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          authService.getSessionCheck();
        }
      },
      (error) => {
        console.error('Error listening to document:', error);
      },
    );
  }, [id, isLoggedIn, listRoles]);

  return (
    <>
      <Drawer
        variant={isDesktop ? 'persistent' : 'temporary'}
        anchor='left'
        open={openDrawer}
        onClose={() => onDrawerChange(false)}
        PaperProps={{ style: { width: '320px', padding: '8px 16px' } }}
      >
        <div className='flex items-center justify-center border-b p-2'>
          <Link to={publicRoute.home.path}>
            <img src={require('assets/icons/Muragi.svg').default} className='h-[40px]' />
          </Link>
        </div>
        <AppMenu />
      </Drawer>

      <AppBar position='sticky' elevation={1} color='inherit'>
        <Toolbar>
          {canOpenDrawer && (
            <IconButton onClick={() => onDrawerChange(!openDrawer)} className='mr-2'>
              <Menu />
            </IconButton>
          )}
          <div className='flex flex-1 items-center space-x-2'>{!isMobile && <AuthMenu />}</div>

          {isLoggedIn ? (
            <div className='flex items-center space-x-2'>
              <Link to={privateRoute.profile.path}>
                <Button variant='outlined' size='small' color='secondary' startIcon={<Person />}>
                  Tài khoản
                </Button>
              </Link>
              {!isMobile && <Chip className='font-bold' label={fullName} avatar={<Avatar>M</Avatar>} />}

              <IconButton onClick={() => dispatch(signOut())}>
                <Logout />
              </IconButton>
            </div>
          ) : (
            <Link to={authRoute.login.url}>
              <Button>Đăng nhập/Đăng ký</Button>
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default AppHeader;

import { useState } from 'react';
import { Typography, IconButton, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { InfoTwoTone } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ViewEditorShortContent } from 'views/Test/components';

type Props = {
  text: string;
  length?: number;
};
const TruncatedTextView = ({ text, length }: Props) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <>
      <Box display='flex' alignItems='center'>
        <Typography sx={{ whiteSpace: 'pre-wrap' }} variant='inherit'>
          <ViewEditorShortContent data={text.slice(0, length ?? 20) + (text.length > (length ?? 20) ? '...' : '')} />
        </Typography>
        {text.length > (length ?? 20) && (
          <IconButton title='Xem đầy đủ nội dung' className='mb-1' onClick={toggleText}>
            <InfoTwoTone />
          </IconButton>
        )}
      </Box>
      <Dialog open={showFullText} maxWidth='sm'>
        <DialogTitle>Thông tin đầy đủ</DialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ViewEditorShortContent data={text} />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant='outlined' color='inherit' onClick={() => setShowFullText(false)}>
            Hủy bỏ
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TruncatedTextView;

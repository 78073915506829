import * as React from 'react';
import clsx from 'clsx';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Root,
  TreeItem2GroupTransition,
} from '@mui/x-tree-view/TreeItem2';
import { useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { useQuery } from '@tanstack/react-query';
import { courseService, homeworkService } from 'services';
import { Card, Chip, Container, Grid } from '@mui/material';
import { BakeryDining, Label, FilePresent, Quiz, VideoFile } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import StudentPaperContentView from './StudentPaperContentView';
import { CardStudentHomework } from 'views/Homework/components';
import ContentComment from './ContentComment';
import ClassroomList from './ClassroomList';
import { privateRoute } from 'routes';
import { ViewEditorContent } from 'views/Test/components';

type Props = {
  course: Course;
};

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

interface StyledTreeItemProps extends Omit<UseTreeItem2Parameters, 'rootRef'>, React.HTMLAttributes<HTMLLIElement> {
  bgColor?: string;
  bgColorForDarkMode?: string;
  color?: string;
  colorForDarkMode?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
}

const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  marginBottom: theme.spacing(0.3),
  color: theme.palette.text.secondary,
  borderRadius: theme.spacing(2),
  paddingRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  '&.expanded': {
    fontWeight: theme.typography.fontWeightRegular,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.focused, &.selected, &.selected.focused': {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    color: 'var(--tree-view-color)',
  },
}));

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
  marginRight: theme.spacing(1),
  svg: {
    fontSize: '2rem',
  },
}));

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(({ theme }) => ({
  marginLeft: 0,
  [`& .content`]: {
    paddingLeft: theme.spacing(2),
  },
}));

const StudentContentList = ({ course }: Props) => {
  const CustomTreeItem = React.forwardRef(function CustomTreeItem(
    props: StyledTreeItemProps & {
      contentNode: CourseContent;
    },
    ref: React.Ref<HTMLLIElement>,
  ) {
    const theme = useTheme();
    const {
      id,
      itemId,
      label,
      disabled,
      children,
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      colorForDarkMode,
      bgColorForDarkMode,
      contentNode,
      ...other
    } = props;

    const { getRootProps, getContentProps, getIconContainerProps, getGroupTransitionProps, status } = useTreeItem2({
      id,
      itemId,
      children,
      label,
      disabled,
      rootRef: ref,
    });

    const style = {
      '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
      '--tree-view-bg-color': theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
      <TreeItem2Provider itemId={itemId}>
        <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
          <CustomTreeItemContent
            {...getContentProps({
              className: clsx('content', {
                expanded: !contentNode.parentId && contentNode.isLearning ? true : status.expanded,
                selected: status.selected,
                focused: status.focused,
              }),
            })}
            onSelect={() => {
              if (contentNode.contentType === 'FOLDER') {
                return;
              }

              setItemChoice(contentNode);
            }}
          >
            <CustomTreeItemIconContainer {...getIconContainerProps()}>
              <TreeItem2Icon status={status} />
            </CustomTreeItemIconContainer>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                p: 0.5,
                pr: 0,
              }}
              onClick={() => {
                if (contentNode.contentType === 'FOLDER') {
                  return;
                }
                setItemChoice(contentNode);
              }}
            >
              <Box component={LabelIcon} color='inherit' sx={{ mr: 1 }} />
              <Typography
                variant={contentNode.contentType === 'FOLDER' ? 'h6' : 'inherit'}
                sx={{ display: 'flex', flexGrow: 1 }}
              >
                {contentNode.contentType === 'FOLDER' ? (
                  contentNode.name
                ) : (
                  <>
                    <Link
                      to='#'
                      onClick={() => {
                        setItemChoice(contentNode);
                      }}
                      className='text-primary-main hover:text-primary-dark'
                    >
                      {contentNode.name}
                    </Link>
                  </>
                )}
              </Typography>

              {contentNode.contentType === 'TEST' &&
                contentNode.allowView &&
                contentNode.isTested &&
                !contentNode.fromPublic && (
                  <Box sx={{ ml: 1 }}>
                    {contentNode.passTest ? (
                      <Chip color='success' className='font-bold' label='PASS' />
                    ) : (
                      <Chip color='error' className='font-bold' label='FAILED' />
                    )}
                  </Box>
                )}

              {contentNode.contentType === 'FOLDER' &&
                contentNode.allowView &&
                contentNode.totalTest &&
                !contentNode.fromPublic && (
                  <Box sx={{ ml: 1 }}>
                    {contentNode.totalNotTest ? (
                      <Chip
                        color='success'
                        className='font-bold'
                        label={`Còn ${contentNode.totalNotTest + (contentNode.totalFailed ?? 0)}/${
                          contentNode.totalTest
                        } bài test`}
                      />
                    ) : contentNode.totalFailed ? (
                      <Chip
                        color='error'
                        className='font-bold'
                        label={`Failed ${contentNode.totalFailed}/${contentNode.totalTest}`}
                      />
                    ) : (
                      <Chip
                        color='success'
                        className='font-bold'
                        label={`Pass ${contentNode.totalPass}/${contentNode.totalTest}`}
                      />
                    )}
                  </Box>
                )}

              {contentNode.isPublic === 1 && contentNode.fromPublic && (
                <Box sx={{ ml: 1 }}>
                  <Chip color='success' className='font-bold' label='PUBLIC' />
                </Box>
              )}
            </Box>
          </CustomTreeItemContent>
          {children && <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />}
        </CustomTreeItemRoot>
      </TreeItem2Provider>
    );
  });

  function EndIcon() {
    return <div style={{ width: 24 }} />;
  }

  type ContentItemProps = {
    node: CourseContent;
  };

  const ContentItemInfo = ({ node }: ContentItemProps) => {
    return (
      <CustomTreeItem
        key={'node_' + node.hashId}
        itemId={node.id.toString()}
        contentNode={node}
        labelIcon={
          node.contentType === 'VIDEO'
            ? VideoFile
            : node.contentType === 'TEST'
            ? Quiz
            : node.contentType === 'HOMEWORK'
            ? BakeryDining
            : node.contentType === 'POST'
            ? FilePresent
            : Label
        }
      >
        {node.children.map((child) => (
          <ContentItemInfo node={child} key={'node_' + child.hashId} />
        ))}
      </CustomTreeItem>
    );
  };
  const [itemChoice, setItemChoice] = React.useState<CourseContent>();
  const [itemView, setItemView] = React.useState<CourseContent>();
  const [myHomework, setMyHomework] = React.useState<MyHomework>();

  const { data, isSuccess } = useQuery(
    ['courseService.fetchMyCourseContents', { id: course.id }],
    () => courseService.fetchMyCourseContents({ id: course.id }),
    { keepPreviousData: true },
  );
  const { list: items = [] } = data ?? {};

  React.useEffect(() => {
    if (itemChoice) {
      courseService
        .getMyCourseContent({
          courseId: course.id,
          uuid: itemChoice?.hashId,
        })
        .then((res) => {
          setItemView(res);
        });

      if (itemChoice.fromPublic) {
        return;
      }

      if (itemChoice.contentType === 'HOMEWORK' && itemChoice.objectId) {
        homeworkService
          .getMyCourseHomework({
            courseId: course.id,
            homeworkId: itemChoice?.objectId!,
          })
          .then((res) => {
            setMyHomework(res);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemChoice]);

  const onTesting = (id: number) => {
    window.open(privateRoute.studentTesting.url({ id: id }), '_blank');
  };

  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        {items.length > 0 ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card variant='outlined' className='p-2'>
                <SimpleTreeView
                  aria-label='course content'
                  slots={{
                    expandIcon: ArrowRightIcon,
                    collapseIcon: ArrowDropDownIcon,
                    endIcon: EndIcon,
                  }}
                  sx={{ flexGrow: 1, maxWidth: 500 }}
                >
                  {items.map((item) => (
                    <ContentItemInfo node={item} key={'node_' + item.id} />
                  ))}
                </SimpleTreeView>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              {itemView && (
                <Card variant='outlined' className='p-4'>
                  <Typography variant='h4' color='primary' className='mb-6'>
                    {itemView.name}
                  </Typography>
                  {itemView.allowView &&
                    (itemView.contentType === 'TEST' ? (
                      itemView.objectId && (
                        <StudentPaperContentView
                          key={'test_' + itemView.hashId}
                          paperId={itemView.objectId}
                          onTesting={onTesting}
                        />
                      )
                    ) : itemView.contentType === 'HOMEWORK' ? (
                      itemView.objectId &&
                      (myHomework ? (
                        <CardStudentHomework key={'hw_' + itemView.hashId} item={myHomework} />
                      ) : (
                        <Typography variant='h5' color='red' align='center'>
                          Không có thông tin bài tập về nhà
                        </Typography>
                      ))
                    ) : (
                      <>
                        <ViewEditorContent key={'content_' + itemView.hashId} data={itemView.detail} onlyView={true} />
                        {!itemView.fromPublic && (
                          <ContentComment key={'comment_' + itemView.hashId} contentId={itemView.id} />
                        )}
                      </>
                    ))}
                  {itemView && !itemView.allowView && !itemView.fromPublic && (
                    <Typography variant='h5' color='red' align='center'>
                      Bạn chưa hoàn thành các bài test ở nội dung trước. Vui lòng thực hiện để được mở các nội dung tiếp
                      theo
                    </Typography>
                  )}
                  {itemView && !itemView.allowView && itemView.fromPublic && (
                    <>
                      <Typography variant='h5' color='red' align='center' className='mb-6'>
                        Nội dung dành riêng cho học viên, vui lòng đăng ký khóa học để xem chi tiết
                      </Typography>
                      <ClassroomList course={course} active={true} />
                    </>
                  )}
                </Card>
              )}
            </Grid>
          </Grid>
        ) : (
          isSuccess && (
            <div className='text-center font-bold text-error'>
              NỘI DUNG KHOÁ HỌC ĐANG ĐƯỢC CẬP NHẬT, VUI LÒNG QUAY LẠI SAU!
            </div>
          )
        )}
      </Container>
    </>
  );
};

export default StudentContentList;

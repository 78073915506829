import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyUploadAdapter from './MyUploadAdapter';

function MyEditor({
  data,
  onChange,
  onBlur,
  onlyContent,
}: {
  data: string;
  onChange: any;
  onBlur?: any;
  onlyContent?: boolean;
}) {
  const handleEditorChange = (event: any, editor: any) => {
    const newData = editor.getData();
    onChange(newData);
  };

  const handleEditorBlur = (event: any, editor: any) => {
    if (onBlur) {
      const newData = editor.getData();
      onBlur(newData);
    }
  };

  const customUploadAdapterPlugin = (editor: any) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return new MyUploadAdapter(loader);
    };
  };

  const editorConfiguration = {
    extraPlugins: [customUploadAdapterPlugin],
    mediaEmbed: {
      previewsInData: true,
    },
  };

  return (
    <div>
      <style>
        {`
          .cke_dialog {
            z-index: 99999 !important;
            display: block !important;
            position: fixed !important;
          }
          .ck-balloon-panel {
            z-index:9999 !important;
          }
          .editor-content ul {
            list-style-type: disc;
            margin-left: 20px;
          }
          .editor-content ol {
            list-style-type: decimal; 
            margin-left: 20px;
          }
          .editor-content li {
            margin-bottom: 10px;
          }
          .editor-content h1 {
            font-size: 2em; 
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .editor-content h2 {
            font-size: 1.75em; 
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .editor-content h3 {
            font-size: 1.5em; 
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .editor-content p {
            font-size: 1em; 
            margin-top: 0.5em;
            margin-bottom: 0.5em;
          }
          .editor-content img {
            max-width: 100%;
            height: auto;
          }
          .editor-content table {
            border-collapse: collapse;
            width: 100%;
          }

          .editor-content th, .editor-content td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }
          .editor-content .align-block-left {
            float: left;
            margin-right: 15px;
          }
          
          .editor-content .block {
            display: block;
            margin-left: auto;
            margin-right: auto;
          }
          
          .editor-content .align-block-right {
            float: right;
            margin-left: 15px;
          }

          .ck-content {
            padding: 1em 1.5em !important;
            overflow-y: scroll !important;
            max-height: 600px;
            ol, ul {
              padding-inline-start: 1em;
            };
            h2 {
              font-size: 1.75em;
              font-weight: bold;
              margin-top: 1em;
              margin-bottom: 0.5em;
            };
            h3 {
              font-size: 1.5em;
              font-weight: bold;
              margin-top: 1em;
              margin-bottom: 0.5em;
            };
            a {
              color: blue; 
            }
          }
        `}
      </style>
      {!onlyContent && <h2>Nội dung bài viết</h2>}
      <CKEditor
        editor={ClassicEditor}
        data={data}
        onBlur={handleEditorBlur}
        onChange={handleEditorChange}
        config={editorConfiguration}
      />
      {!onlyContent && (
        <div>
          <h3>Nội dung:</h3>
          <div dangerouslySetInnerHTML={{ __html: data }} className='editor-content' />
        </div>
      )}
    </div>
  );
}

export default MyEditor;

import { LoadingButton } from '@mui/lab';
import { Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { TextFieldPassword } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { signIn, signOut, updateProfile, profileSelector } from 'reducers/profileSlice';
import { authRoute } from 'routes';
import { authService } from 'services';

const LoginScreen = () => {
  const { sessionId } = useSelector(profileSelector);
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');
  if (from === 'forgot') {
    signOut();
    enqueueSnackbar('Reset mật khẩu thành công, vui lòng đăng nhập lại');
  }
  if (from === 'register') {
    signOut();
    enqueueSnackbar('Kích hoạt tài khoản thành công, vui lòng đăng nhập hệ thống để đăng ký khóa học');
  }
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm<LoginBody>({
    defaultValues: {
      userName: '',
      password: '',
    },
  });

  const { mutate: login, isLoading } = useMutation(authService.login, {
    onSuccess: ({ accessToken, refreshToken, ...info }, body) => {
      dispatch(signIn({ accessToken, refreshToken }));
      authService.getProfile().then((profile) => {
        dispatch(updateProfile(profile));
      });
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      login({ ...values, sessionId: sessionId });
    })();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  return (
    <Container maxWidth='sm'>
      <Paper className='space-y-6 p-10 mobile:px-4'>
        <Typography variant='h3' color='primary' className='text-center'>
          Đăng nhập
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='userName'
              defaultValue=''
              control={control}
              rules={{
                required: 'Email/Số điện thoại là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Email/Số điện thoại'
                  onKeyDown={handleKeyDown}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='password'
              defaultValue=''
              control={control}
              rules={{
                required: 'Mật khẩu is required',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldPassword
                  {...field}
                  fullWidth
                  label='Mật khẩu'
                  onKeyDown={handleKeyDown}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>

        <div className='flex justify-end'>
          <Link to={authRoute.resetPassword.url} className='font-bold text-primary-main hover:text-primary-dark'>
            Quên mật khẩu?
          </Link>
        </div>

        <div className='flex justify-center'>
          <LoadingButton
            fullWidth
            variant='contained'
            size='large'
            className='md:w-[50%]'
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Đăng nhập
          </LoadingButton>
        </div>

        <div className='flex justify-center gap-2 mobile:flex-col mobile:items-center'>
          <span>Chưa có tài khoản?</span>
          <Link to={authRoute.register.url} className='font-bold text-primary-main hover:text-primary-dark'>
            Đăng ký ngay
          </Link>
        </div>
      </Paper>
    </Container>
  );
};

export default LoginScreen;

import { Button, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { classroomService } from 'services';
import { PopupDocAdd, PopupDocUpdate, PopupDocDelete } from '.';
import { Delete, Edit } from '@mui/icons-material';

export const DocMethodTypes = [
  { value: 'BEFORE', label: 'Trước khóa học' },
  { value: 'OFFICIAL', label: 'Chính thức' },
];

type Props = {
  classroom: Classroom;
  editable: boolean;
  note?: string;
};

const DocList = ({ classroom, editable, note }: Props) => {
  const [openDocAdd, setOpenDocAdd] = useState(false);
  const [openDocUpdate, setOpenDocUpdate] = useState(false);
  const [openDocDelete, setOpenDocDelete] = useState(false);
  const [itemChoice, setItemChoice] = useState<ClassroomDoc>();
  const { dataSearch } = useSearch();

  const { data, isFetching } = useQuery(
    [`classroomService.fetchClassroomDocs${classroom.id}`, dataSearch],
    () => classroomService.fetchClassroomDocs({ ...dataSearch, id: classroom.id }),
    { keepPreviousData: true },
  );
  const { list: items = [] } = data ?? {};

  return (
    <>
      <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
        <span>Danh sách tài liệu của lớp {classroom.name}</span>
        {editable && (
          <Button
            onClick={() => {
              setOpenDocAdd(true);
            }}
          >
            Thêm tài liệu
          </Button>
        )}
      </Typography>
      {note && (
        <Typography variant='inherit' color='red'>
          <span>({note})</span>
        </Typography>
      )}

      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 64}
        rows={items}
        rowCount={items.length}
        hideFooter={true}
        columns={
          editable
            ? [
                {
                  field: 'doc.docType',
                  headerName: 'Loại tài liệu',
                  flex: 1,
                  minWidth: 120,
                  sortable: false,
                  renderCell: ({ row }) => <div>{row.doc.docType}</div>,
                },
                {
                  field: 'doc.name',
                  headerName: 'Tên tài liệu',
                  flex: 1,
                  minWidth: 750,
                  sortable: false,
                  renderCell: ({ row }) => (
                    <>
                      <div>
                        {row.doc.name}
                        <br />
                        <Link to={row.doc.url} target='_blank' className='text-primary-main hover:text-primary-dark'>
                          {row.doc.url}
                        </Link>
                      </div>
                    </>
                  ),
                },
                {
                  field: 'doc.method',
                  headerName: 'Hình thức',
                  flex: 1,
                  minWidth: 120,
                  sortable: false,
                  renderCell: ({ row }) => (
                    <div className='font-bold'>
                      {DocMethodTypes.find((item) => item.value === row.doc.method)?.label}
                    </div>
                  ),
                },
                {
                  field: 'actions',
                  headerName: 'Hành động',
                  flex: 1,
                  minWidth: 120,
                  sortable: false,
                  renderCell: ({ row }) => (
                    <div className='flex space-x-3'>
                      <Tooltip title='Sửa tài liệu'>
                        <Button
                          size='small'
                          color='info'
                          onClick={() => {
                            setOpenDocUpdate(true);
                            setItemChoice(row);
                          }}
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                      <Tooltip title='Xóa tài liệu'>
                        <Button
                          size='small'
                          color='error'
                          onClick={() => {
                            setOpenDocDelete(true);
                            setItemChoice(row);
                          }}
                        >
                          <Delete />
                        </Button>
                      </Tooltip>
                    </div>
                  ),
                },
              ]
            : [
                {
                  field: 'doc.docType',
                  headerName: 'Loại tài liệu',
                  flex: 1,
                  minWidth: 120,
                  sortable: false,
                  renderCell: ({ row }) => <div>{row.doc.docType}</div>,
                },
                {
                  field: 'doc.name',
                  headerName: 'Tên tài liệu',
                  flex: 1,
                  minWidth: 740,
                  sortable: false,
                  renderCell: ({ row }) => (
                    <>
                      <div>
                        {row.doc.name}
                        <br />
                        <Link to={row.doc.url} target='_blank' className='text-primary-main hover:text-primary-dark'>
                          {row.doc.url}
                        </Link>
                      </div>
                    </>
                  ),
                },
                {
                  field: 'doc.method',
                  headerName: 'Hình thức',
                  flex: 1,
                  minWidth: 140,
                  sortable: false,
                  renderCell: ({ row }) => (
                    <div className='font-bold'>
                      {DocMethodTypes.find((item) => item.value === row.doc.method)?.label}
                    </div>
                  ),
                },
              ]
        }
      />
      <Dialog maxWidth='sm' open={openDocAdd}>
        <PopupDocAdd classroomId={classroom.id} onClose={() => setOpenDocAdd(false)} />
      </Dialog>
      {itemChoice && (
        <>
          <Dialog maxWidth='sm' open={openDocUpdate}>
            <PopupDocUpdate classroomId={classroom.id} doc={itemChoice!.doc} onClose={() => setOpenDocUpdate(false)} />
          </Dialog>
          <Dialog maxWidth='sm' open={openDocDelete}>
            <PopupDocDelete classroomId={classroom.id} id={itemChoice!.id} onClose={() => setOpenDocDelete(false)} />
          </Dialog>
        </>
      )}
    </>
  );
};

export default DocList;

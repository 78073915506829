import { Grid } from '@mui/material';
import StudentContentList from './StudentContentList';

type Props = {
  course: Course;
};

const TabCourseContent = ({ course }: Props) => {
  return (
    <div>
      <Grid container spacing={3} className='justify-around'>
        <StudentContentList course={course} />
      </Grid>
    </div>
  );
};

export default TabCourseContent;

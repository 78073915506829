import { useParams } from 'react-router-dom';
import { PaperTesting } from './components';

const StudentPaperTesting = () => {
  const { id, from } = useParams();

  return <PaperTesting paperAssignId={+id!} from={from} />;
};

export default StudentPaperTesting;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoggedIn: true,
  } as ProfileType,
  reducers: {
    signIn: (state, { payload }: PayloadAction<ProfileType>) => {
      const profile = {
        ...payload,
        isLoggedIn: true,
      };
      return profile;
    },
    signOut: (state) => {
      const profile = { isLoggedIn: false, sessionId: state.sessionId };
      return profile;
    },
    updateProfile: (state, { payload }: PayloadAction<ProfileType>) => {
      const profile = {
        ...state,
        ...payload,
        isStudent: payload.listRoles?.includes('STUDENT'),
        isAdmin: payload.listRoles?.includes('ADMIN'),
      };
      return profile;
    },
  },
});

export const { signIn, signOut, updateProfile } = profileSlice.actions;

export const profileSelector = ({ profile }: RootState) => profile;

import {
  Button,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from 'env';
import { formatDateTime, formatNumber } from 'utils/common';
import { privateRoute } from 'routes';
import PopupMyPaperResultList from './PopupMyPaperResultList';
import { useState } from 'react';
import { paperService, queryClient } from 'services';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';

type Props = {
  paper: TestPaper;
  onTesting?: (paperAssignId: number) => void;
};

const CardPaper = ({ paper, onTesting }: Props) => {
  const { isLoggedIn } = useSelector(profileSelector);
  const navigate = useNavigate();
  const [paperAssignId, setPaperAssignId] = useState(0);
  const [resultTestingId, setResultTestingId] = useState(0);
  const [openHistory, setOpenHistory] = useState(false);
  const [openDeleteTesting, setOpenDeleteTesting] = useState(false);
  const [openDeleteWait, setOpenDeleteWait] = useState(false);

  const { mutate: initResult, isLoading: isLoadingInit } = useMutation(paperService.postInitTesting, {
    onSuccess: (res: CheckTestingStudent) => {
      setPaperAssignId(res.paperAssignId);
      if (res.testingResultId) {
        setResultTestingId(res.testingResultId!);
        setOpenDeleteTesting(true);
      } else if (res.waitApprovedResultId) {
        setResultTestingId(res.waitApprovedResultId!);
        setOpenDeleteWait(true);
      } else {
        queryClient.removeQueries([`paperService.getTestingData_${res.paperAssignId}`]);
        if (onTesting) {
          onTesting(res.paperAssignId);
        } else {
          navigate(privateRoute.studentTesting.url({ id: res.paperAssignId }));
        }
      }
    },
  });

  const { mutate: deleteResult, isLoading: isLoadingDelete } = useMutation(paperService.deleteMyPaperResult, {
    onSuccess: () => {
      setOpenDeleteTesting(false);
      setOpenDeleteWait(false);
      initResult({ paperId: paper.id, paperAssignId: paperAssignId });
    },
  });

  const handleClickTesting = () => {
    initResult({ paperId: paper.id, paperAssignId: paper.paperAssignId });
  };

  const handleClickDelete = () => {
    deleteResult({
      id: resultTestingId,
    });
  };

  return (
    <>
      <Paper
        variant='outlined'
        className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
      >
        <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
          <CardMedia
            image={`${API_URL}/api/v1/files/public/quizz.png`}
            className='h-[180px] min-w-[240px] rounded-lg border bg-contain hover:shadow-md mobile:w-full'
          >
            <div></div>
          </CardMedia>
          <div className='space-y-2'>
            <Typography variant='h4' className='hover:text-primary-main'>
              {paper.name}
            </Typography>
            <div>
              <span className='text-black/60'>Số lượng câu hỏi: </span>
              {formatNumber(paper.totalQuestion)} câu
            </div>
            {!paper.result && (
              <div>
                <span className='text-black/60'>Số lần test tối đa: </span>
                {paper.maxTime ? <>{formatNumber(paper.maxTime)} lần</> : <>Không giới hạn</>}
              </div>
            )}
            {paper.expiredAt && (
              <div>
                <span className='text-black/60'>Thời hạn: </span>
                {formatDateTime(paper.expiredAt)}
              </div>
            )}
            {paper.result && (
              <>
                <div>
                  <span className='text-black/60'>Đã làm: </span>
                  {paper.maxTime
                    ? formatNumber(paper.numberTested) + '/' + formatNumber(paper.maxTime)
                    : formatNumber(paper.numberTested)}{' '}
                  lần
                </div>
                <div>
                  <Typography variant='h5'>
                    Điểm: {formatNumber(paper.result.point)}/{formatNumber(paper.result.maxPoint)}
                  </Typography>
                </div>
                {!paper.passPercent && (
                  <div>
                    <Typography variant='h5'>Tỷ lệ đạt: {paper.result.percentCorrect} %</Typography>
                  </div>
                )}
                {paper.result.assessmentType && (
                  <div>
                    <span className='text-black/60'>xếp loại: </span>
                    {paper.result.assessmentType?.name}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className='flex flex-col items-end justify-between'>
          {isLoggedIn && (
            <div className='space-y-2'>
              <div className='flex justify-end'>
                {paper.passPercent && paper.result ? (
                  <Chip
                    label={paper.result.isPass ? 'PASS' : 'FAILED'}
                    color={paper.result.isPass ? 'success' : 'error'}
                  />
                ) : (
                  <Chip
                    label={paper.result ? 'Đã thực hiện' : 'Chưa thực hiện'}
                    color={paper.result ? 'success' : 'primary'}
                  />
                )}
              </div>
            </div>
          )}
          {isLoggedIn && (
            <div className='mt-6 flex space-x-2'>
              {paper.result && (
                <>
                  <Tooltip title='Xem thông tin chi tiết bài làm'>
                    <Link to={privateRoute.studentPaperResultView.url({ id: paper.result.id })}>
                      <Button className='whitespace-nowrap' size='small' color='success'>
                        Chi tiết
                      </Button>
                    </Link>
                  </Tooltip>
                  <Tooltip title='Lịch sử làm bài test'>
                    <Button
                      className='whitespace-nowrap'
                      size='small'
                      color='secondary'
                      onClick={() => setOpenHistory(true)}
                    >
                      Lịch sử
                    </Button>
                  </Tooltip>
                </>
              )}
              {paper.result?.testStatus === -1 && paper.result?.pausedAt ? (
                <Tooltip title='Làm tiếp bài test đã dừng trước đó'>
                  <LoadingButton
                    loading={isLoadingInit}
                    className='whitespace-nowrap'
                    size='small'
                    color='primary'
                    variant='contained'
                    onClick={handleClickTesting}
                  >
                    Làm tiếp
                  </LoadingButton>
                </Tooltip>
              ) : (
                <Tooltip title='Làm bài test'>
                  <LoadingButton
                    loading={isLoadingInit}
                    className='whitespace-nowrap'
                    size='small'
                    color='primary'
                    variant='contained'
                    onClick={handleClickTesting}
                  >
                    Làm bài
                  </LoadingButton>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </Paper>
      <Dialog maxWidth='md' open={openHistory}>
        <PopupMyPaperResultList paperAssignId={paper.paperAssignId} onClose={() => setOpenHistory(false)} />
      </Dialog>
      <Dialog open={openDeleteTesting || openDeleteWait}>
        <DialogTitle>Xác nhận làm lại bài test</DialogTitle>

        <DialogContent>
          {openDeleteTesting ? (
            <div className='text-center'>
              Bạn đang thực hiện làm bài test trước đó. Hệ thống sẽ tự động hủy bài làm này để thực hiện bài test mới.
              Vui lòng nhấn xác nhận để tiếp tục làm bài
            </div>
          ) : (
            <div className='text-center'>
              Bài làm gần nhất của bạn đang chờ duyệt điểm. Nếu bạn muốn làm lại, vui lòng xác nhận đồng ý Hủy bài cũ
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton
            variant='outlined'
            color='inherit'
            onClick={() => {
              setOpenDeleteTesting(false);
              setOpenDeleteWait(false);
            }}
          >
            Hủy bỏ
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoadingDelete} onClick={handleClickDelete}>
            Xác nhận
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardPaper;

import { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr-react/plyr.css';
import Hls from 'hls.js';
import { LoadingButton } from '@mui/lab';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { store } from 'reducers/store';

type PopupVideoPlayerProps = PopupController & {
  src: string;
};

const PopupVideoPlayer = ({ src, onClose }: PopupVideoPlayerProps) => {
  const { accessToken }: ProfileType = store.getState().profile;
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      new Plyr(video, {
        controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
      });

      const videoUrl = `${src}&token=${accessToken}`;
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(video);
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoUrl;
      }
    }
  }, [accessToken, src]);

  return (
    <>
      <DialogTitle>Phát Video</DialogTitle>

      <DialogContent>
        <video ref={videoRef} className='plyr' controls />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupVideoPlayer;

import { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr-react/plyr.css';
import Hls from 'hls.js';
import { store } from 'reducers/store';

type VideoPlayerProps = {
  src: string;
};

const VideoPlayer = ({ src }: VideoPlayerProps) => {
  const { accessToken }: ProfileType = store.getState().profile;
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      new Plyr(video, {
        controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
      });

      const videoUrl = `${src}&token=${accessToken}`;
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(video);
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoUrl;
      }
    }
  }, [accessToken, src]);

  return <video ref={videoRef} className='plyr' controls />;
};

export default VideoPlayer;

import { CheckCircle, CheckOutlined, ContentCopyOutlined, Error, Sync, VideocamOutlined } from '@mui/icons-material';
import { Button, Container, Dialog, IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useEffect, useState } from 'react';
import { videoService } from 'services';
import { formatDateTime } from 'utils/common';
import { VideoSearch, PopupUploadVideo } from './components';
import PopupVideoPlayer from './components/PopupVideoPlayer';

const VideoList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  useEffect(() => {}, [dataSearch]);

  const { data, isFetching } = useQuery(
    ['videoService.fetchVideos', dataSearch],
    () => videoService.fetchVideos(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};
  const [openUploadVideo, setOpenUploadVideo] = useState(false);
  const [openPlayVideo, setOpenPlayVideo] = useState(false);
  const [itemChoice, setItemChoice] = useState<Video>();
  const [copied, setCopied] = useState(false);

  const copyLink = (content: string) => {
    navigator.clipboard.writeText(content).then(() => {
      setCopied(true);
    });
  };

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách video
      </Typography>

      <VideoSearch onChange={onSearchChange} />
      <div className='mt-6 flex justify-end space-x-3'>
        <Button color='success' onClick={() => setOpenUploadVideo(true)}>
          Thêm mới
        </Button>
      </div>
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 96}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'title',
            headerName: 'Thông tin video',
            flex: 1,
            minWidth: 450,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                <div className='font-bold'>{row.title}</div>
                <div className='mt-1'>{row.link}</div>
              </div>
            ),
          },
          {
            field: 'createdAt',
            headerName: 'Ngày tạo',
            minWidth: 160,
            sortable: false,
            renderCell: ({ row }) => <div>{formatDateTime(row.createdAt)}</div>,
          },
          {
            field: 'isActive',
            headerName: 'Ready',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => (
              <>
                {row.isActive === 1 && (
                  <div className='flex space-x-3'>
                    <IconButton edge='end' aria-label='Video đã sẵn sàng' title='Video đã sẵn sàng' color='success'>
                      <CheckCircle />
                    </IconButton>
                  </div>
                )}

                {row.isActive === -1 && (
                  <div className='flex space-x-3'>
                    <IconButton edge='end' aria-label='Video bị lỗi' title='Video bị lỗi' color='error'>
                      <Error />
                    </IconButton>
                  </div>
                )}

                {(!row.isActive || row.isActive === 0) && (
                  <div className='flex space-x-3'>
                    <IconButton edge='end' aria-label='Video đang xử lí' title='Video đang xử lí' color='info'>
                      <Sync />
                    </IconButton>
                  </div>
                )}
              </>
            ),
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Button
                  size='small'
                  color='info'
                  variant='outlined'
                  onClick={() => {
                    setItemChoice(row);
                    setOpenPlayVideo(true);
                  }}
                >
                  <VideocamOutlined />
                </Button>
                <Button
                  color='success'
                  size='small'
                  variant='outlined'
                  onClick={() => {
                    setItemChoice(row);
                    copyLink(row.link);
                  }}
                >
                  {copied && row.id === itemChoice?.id ? <CheckOutlined /> : <ContentCopyOutlined />}
                </Button>
              </div>
            ),
          },
        ]}
      />
      <Dialog open={openUploadVideo} maxWidth='md'>
        <PopupUploadVideo onClose={() => setOpenUploadVideo(false)} />
      </Dialog>
      <Dialog open={openPlayVideo && !!itemChoice?.link} maxWidth='md'>
        <PopupVideoPlayer src={itemChoice?.link!} onClose={() => setOpenPlayVideo(false)} />
      </Dialog>
    </Container>
  );
};

export default VideoList;

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { queryClient, videoService } from 'services';
import { TextEditor } from 'views/Post';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      {label && <span>{label}: </span>}
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const PopupUploadVideo = ({ onClose }: PopupController) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const { control, handleSubmit, setValue } = useForm<UploadVideoBody>();

  const { mutate: uploadVideo, isLoading } = useMutation(videoService.uploadVideo, {
    onSuccess: () => {
      enqueueSnackbar('Tạo video thành công');
      queryClient.invalidateQueries(['videoService.fetchVideos']);
      onClose();
    },
  });

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) {
      setFile(null);
    } else {
      setFile(fileList[0]);
      setValue('title', fileList[0].name);
    }
  };

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      if (!file) {
        enqueueSnackbar('Vui lòng chọn file video', { variant: 'error' });
        return;
      }
      const formData = new FormData();

      formData.append('file', file);
      formData.append('title', values.title);
      formData.append('description', values.description ?? '');
      uploadVideo(formData);
    })();
  };

  return (
    <>
      <DialogTitle>Thêm mới Video</DialogTitle>

      <DialogContent>
        <Grid container spacing={3} className='mt-1'>
          <Grid item xs={12}>
            <Controller
              name='title'
              defaultValue=''
              control={control}
              rules={{
                required: 'Tên video là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Tên video'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h6' color='info' className='mb-2'>
              <span>Mô tả</span>
            </Typography>
            <Controller
              name='description'
              defaultValue=''
              control={control}
              render={({ field }) => <TextEditor data={field.value ?? ''} onChange={field.onChange} />}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <GridInfo label='File video' value='' />
          </Grid>
          <Grid item md={8} xs={12}>
            <div className='flex justify-start'>
              <input ref={inputRef} type='file' hidden onChange={handleChangeFile} />
              <div className='space-x-3'>
                <LoadingButton variant='outlined' size='small' onClick={() => inputRef.current?.click()}>
                  Chọn file
                </LoadingButton>
              </div>
              {file && (
                <p className='text-gray-700 ml-4 mt-1 text-sm'>
                  <strong>{file.name}</strong>
                </p>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' disabled={!file} loading={isLoading} onClick={handleClickSubmit}>
          Tạo mới
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupUploadVideo;

import { client } from './axios';

const fetchRegisters = (body: RegisterSearchParams): Promise<PaginateEmailResponse<Register>> =>
  client.post(`/api/v1/registers/list`, body);
const fetchMyRegisters = (params: RegisterSearchParams): Promise<PaginateResponse<Register>> =>
  client.get(`/api/v1/registers/me`, { params });
const fetchMyReserves = (params: ReserveSearchParams): Promise<PaginateResponse<Reserve>> =>
  client.get(`/api/v1/registers/me/reserves`, { params });

const getRegisterInfo = ({ id }: ByID): Promise<Register> => client.get(`/api/v1/registers/${id}`);
const getMyRegisterInfo = ({ id }: ByID): Promise<Register> => client.get(`/api/v1/registers/me/${id}`);
const getRegisterQr = ({ id }: ByID): Promise<RegisterQrCode> => client.get(`/api/v1/registers/${id}/qr`);

const shareRegisterDocs = (body: ShareDocBody): Promise<SuccessResponse> => client.post(`/api/v1/registers/docs`, body);
const fetchSharedDocs = (body: ShareDocSearchParams): Promise<PaginateResponse<ShareDoc>> =>
  client.post(`/api/v1/registers/docs/list`, body);
const fetchReserveClassroom = ({ id }: ByID): Promise<PaginateResponse<Classroom>> =>
  client.get(`/api/v1/registers/${id}/reserve-classrooms`);

const registerCreate = (body: RegisterClassroomBody): Promise<Register> => client.post(`/api/v1/registers`, body);
const registerEmail = (body: RegisterEmailBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/registers/emails`, body);
const registerApprove = ({ id, ...body }: ByID & ApproveRegisterBody): Promise<Register> =>
  client.put(`/api/v1/registers/${id}/approve-payment`, body);
const registerCancel = ({ id, ...body }: ByID & ApproveRegisterBody): Promise<Register> =>
  client.put(`/api/v1/registers/${id}/cancel`, body);
const registerUpdatePromotion = ({ id, ...body }: ByID & RegisterUpdatePromotionBody): Promise<Register> =>
  client.put(`/api/v1/registers/${id}/promotion`, body);
const registerReserve = ({ id, ...body }: ByID & RegisterClassroomBody): Promise<Register> =>
  client.put(`/api/v1/registers/${id}/reserve`, body);

const importPayment = (body: FormData): Promise<ImportPaymentResponse> =>
  client.post(`/api/v1/registers/payment/import`, body);

const getMyPromotion = ({ classroomId }: { classroomId: number }): Promise<RegisterPromotion> =>
  client.get(`/api/v1/registers/me/${classroomId}/promotion`);

const addZalo = ({ id }: ByID): Promise<SuccessResponse> => client.put(`/api/v1/registers/${id}/zalo`);

const registerService = {
  fetchRegisters,
  fetchMyRegisters,
  getRegisterInfo,
  getMyRegisterInfo,
  getRegisterQr,
  fetchMyReserves,

  shareRegisterDocs,
  fetchSharedDocs,

  fetchReserveClassroom,

  registerCreate,
  registerEmail,
  registerApprove,
  registerCancel,
  registerUpdatePromotion,
  registerReserve,

  importPayment,

  getMyPromotion,
  addZalo,
};

export default registerService;

import { useEffect } from 'react';
interface RestrictCopyCutProps {
  window: Window;
}

export const useRestrictCopyCut = (props: RestrictCopyCutProps) => {
  const preventAction = (e: Event) => {
    e.preventDefault();
  };

  useEffect(() => {
    ['copy', 'cut'].forEach((action) => {
      action && window.addEventListener(action, preventAction);
    });
    return () => {
      ['copy', 'cut'].forEach((action) => {
        action && window.removeEventListener(action, preventAction);
      });
    };
  }, [props.window]);

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);
};

import { client } from './axios';

const fetchVideos = (body: VideoSearchParams): Promise<PaginateResponse<Video>> =>
  client.post(`/api/v1/videos/list`, body);
const uploadVideo = (body: FormData): Promise<SuccessResponse> => client.post(`/api/v1/videos/upload`, body);
const videoService = {
  fetchVideos,
  uploadVideo,
};

export default videoService;

import { Paper } from '@mui/material';
import { ViewEditorShortContent } from 'views/Test/components';

type Props = {
  course: Course;
};

const TabCourseTarget = ({ course }: Props) => {
  return (
    <Paper className='mx-auto max-w-3xl bg-[orange]/10 p-4'>
      <div className='flex space-x-10'>
        <span className='text-black/60'>Đối tượng phù hợp:</span>
        {course.suitableObjectList.length > 0 ? (
          <div className='space-y-2'>
            {course.suitableObjectList.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </div>
        ) : (
          <div className='space-y-2'>
            <ViewEditorShortContent data={course.suitableObject} />
          </div>
        )}
      </div>
    </Paper>
  );
};

export default TabCourseTarget;

import React, { useEffect, useRef } from 'react';
import VideoPlayer from './VideoPlayer';
import { store } from 'reducers/store';
import { createRoot } from 'react-dom/client';

function ViewEditorContent({ data, onlyView }: { data?: string; onlyView?: boolean }) {
  const { accessToken }: ProfileType = store.getState().profile;

  const processEditorContent = (content: string): { html: string; videos: { index: number; src: string }[] } => {
    if (!content) return { html: '', videos: [] };

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    const videoList: { index: number; src: string }[] = [];
    let index = 0;

    doc.querySelectorAll('video source').forEach((source) => {
      const videoUrl = source.getAttribute('src');
      if (videoUrl && videoUrl.includes('/api/v1/videos/watch?v=')) {
        const newUrl = `${videoUrl}&token=${accessToken}`;

        videoList.push({ index, src: newUrl });

        // Thay thế video bằng placeholder `<span data-video-id="{index}"></span>`
        const span = doc.createElement('span');
        span.setAttribute('data-video-id', index.toString());
        source.closest('video')?.replaceWith(span);

        index++;
      }
    });

    // Xử lý thẻ <a> có chứa link video API
    doc.querySelectorAll('a').forEach((link) => {
      const href = link.getAttribute('href');
      if (href && href.includes('/api/v1/videos/watch?v=')) {
        const newUrl = `${href}&token=${accessToken}`;

        videoList.push({ index, src: newUrl });

        // Thay thế thẻ <a> bằng <span data-video-id="index">
        const span = document.createElement('span');
        span.setAttribute('data-video-id', index.toString());
        link.replaceWith(span);
        index++;
      }
    });

    return { html: doc.body.innerHTML, videos: videoList };
  };

  const { html, videos } = processEditorContent(data || '');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) return;
    videos.forEach((video) => {
      const span = contentRef.current?.querySelector(`span[data-video-id="${video.index}"]`);

      if (span) {
        const container = document.createElement('div');
        container.classList.add('mt-2', 'mb-2');
        span.replaceWith(container);

        // Render React component vào vị trí của <span>
        createRoot(container).render(<VideoPlayer key={video.index} src={video.src} />);
      }
    });
  }, [html, videos]);

  const preventAction = (e: Event) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (onlyView) {
      ['copy', 'cut'].forEach((action) => {
        action && window.addEventListener(action, preventAction);
      });
      return () => {
        ['copy', 'cut'].forEach((action) => {
          action && window.removeEventListener(action, preventAction);
        });
      };
    }
  }, [onlyView]);

  useEffect(() => {
    if (onlyView) {
      const handleContextmenu = (e: any) => {
        e.preventDefault();
      };
      document.addEventListener('contextmenu', handleContextmenu);
      return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu);
      };
    }
  }, [onlyView]);
  return (
    <div>
      <style>
        {`
          .editor-content ul {
            list-style-type: disc;
            margin-left: 20px;
          }
          .editor-content ol {
            list-style-type: decimal; 
            margin-left: 20px;
          }
          .editor-content li {
            margin-bottom: 10px;
          }
          .editor-content h1 {
            font-size: 2em; 
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .editor-content h2 {
            font-size: 1.75em; 
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .editor-content h3 {
            font-size: 1.5em; 
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .editor-content p {
            font-size: 1em; 
            margin-top: 0.5em;
            margin-bottom: 0.5em;
          }
          .editor-content img {
            max-width: 100%;
            height: auto;
          }
          .editor-content table {
            border-collapse: collapse;
            width: 100%;
          }

          .editor-content th, .editor-content td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }
          .editor-content .align-block-left {
            float: left;
            margin-right: 15px;
          }
          
          .editor-content .block {
            display: block;
            margin-left: auto;
            margin-right: auto;
          }
          
          .editor-content .align-block-right {
            float: right;
            margin-left: 15px;
          }

          .ck-content {
            padding: 1em 1.5em !important;
            overflow-y: scroll !important;
            max-height: 600px;
            ol, ul {
              padding-inline-start: 1em;
            };
            h2 {
              font-size: 1.75em;
              font-weight: bold;
              margin-top: 1em;
              margin-bottom: 0.5em;
            };
            h3 {
              font-size: 1.5em;
              font-weight: bold;
              margin-top: 1em;
              margin-bottom: 0.5em;
            };
            a {
              color: blue; 
            }
          }
        `}
      </style>
      <div ref={contentRef} dangerouslySetInnerHTML={{ __html: html }} className='editor-content'></div>
    </div>
  );
}

export default ViewEditorContent;

import { InfoOutlined } from '@mui/icons-material';
import { Button, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { adminRoute } from 'routes';
import { commentService } from 'services';
import { formatDateTime } from 'utils/common';
import { CommentSearch } from './components';
import { TruncatedTextView } from 'views/Survey/components';

const CommentList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  useEffect(() => {}, [dataSearch]);

  const { data, isFetching } = useQuery(
    ['commentService.fetchListComments', dataSearch],
    () => commentService.fetchListComments(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};
  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách comment bài viết
      </Typography>
      <CommentSearch onChange={onSearchChange} />
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 128}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'postTitle',
            headerName: 'Tiêu đề bài viết',
            flex: 1,
            minWidth: 220,
            sortable: false,
            renderCell: ({ row }) => <TruncatedTextView text={row.postTitle ?? ''} length={100} />,
          },
          {
            field: 'user',
            headerName: 'Người comment',
            flex: 1,
            minWidth: 240,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.fullName}
                <br />
                {row.email}
                <br />
                {row.phone}
              </div>
            ),
          },
          {
            field: 'commentText',
            headerName: 'Nội dung',
            minWidth: 420,
            sortable: false,
            renderCell: ({ row }) => <TruncatedTextView text={row.commentText ?? ''} length={150} />,
          },
          {
            field: 'createdAt',
            headerName: 'Ngày comment',
            minWidth: 160,
            sortable: false,
            renderCell: ({ row }) => <div>{formatDateTime(row.createdAt)}</div>,
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 140,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Link
                  to={
                    row.courseId
                      ? adminRoute.courseDetail.url({ id: row.courseId, contentId: row.courseContentId })
                      : adminRoute.PostView.url({ uuid: row.postId })
                  }
                >
                  <Button size='small' color='info' variant='outlined'>
                    <InfoOutlined />
                  </Button>
                </Link>
              </div>
            ),
          },
        ]}
      />
    </Container>
  );
};

export default CommentList;

import { Chip, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { TruncatedTextView } from 'views/Survey/components';
import { formatDateTime } from 'utils/common';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { homeworkService } from 'services';

type Props = PopupController & {
  caseStudy: CaseStudy;
};
const PopupHomeworkList = ({ caseStudy, onClose }: Props) => {
  const { dataSearch, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    [`homeworkService.fetchHomeworkAnswers_${caseStudy.id}`, dataSearch],
    () =>
      homeworkService.fetchHomeworkAnswers({
        ...dataSearch,
        userId: caseStudy.userId,
        classroomId: caseStudy.classroomId,
      }),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  return (
    <>
      <DialogTitle>Danh sách bài tập về nhà</DialogTitle>

      <DialogContent>
        <DataGrid
          loading={isFetching}
          getRowId={(row) => row.id}
          getRowHeight={() => 96}
          rows={items}
          rowCount={total}
          onPaginationModelChange={onPaginationChange}
          columns={[
            {
              field: 'user.Email',
              headerName: 'Học viên',
              flex: 1,
              minWidth: 300,
              sortable: false,
              renderCell: ({ row }) => (
                <>
                  <div>
                    {row.user?.fullName}
                    <br />
                    {row.user?.email}
                    <br />
                    {row.user?.phone}
                  </div>
                </>
              ),
            },
            {
              field: 'caseStudy',
              headerName: 'Case study',
              minWidth: 240,
              sortable: false,
              renderCell: ({ row }) => <TruncatedTextView text={row.caseStudy?.name ?? ''} />,
            },
            {
              field: 'link',
              headerName: 'Link bài làm',
              minWidth: 240,
              sortable: false,
              renderCell: ({ row }) => (
                <div>
                  <Link to={row.link} target='_blank' className='text-primary-main hover:text-primary-dark'>
                    {row.homework.name}
                  </Link>
                </div>
              ),
            },
            {
              field: 'lastTeacherComment',
              headerName: 'Nhận xét gần nhất',
              minWidth: 240,
              sortable: false,
              renderCell: ({ row }) => <TruncatedTextView text={row.lastTeacherComment ?? ''} />,
            },
            {
              field: 'user',
              headerName: 'Người chữa bài',
              minWidth: 200,
              sortable: false,
              renderCell: ({ row }) => <div>{row.mentorUser?.fullName}</div>,
            },
            {
              field: 'date',
              headerName: 'Ngày tác động',
              minWidth: 150,
              flex: 1,
              align: 'center',
              sortable: false,
              renderCell: ({ row }) => (
                <div>{row.lastReviewedAt ? formatDateTime(row.lastReviewedAt) : formatDateTime(row.lastSentAt)}</div>
              ),
            },
            {
              field: 'status',
              headerName: 'Trạng thái bài',
              minWidth: 180,
              sortable: false,
              renderCell: ({ row }) => (
                <div>
                  {row.status === 'EDITING' && <Chip label='Chờ sửa bài' color='info' />}
                  {row.status === 'FINISHED' && <Chip label='Hoàn thành' color='success' />}
                  {row.status === 'REVIEWING' && <Chip label='Chờ review lần 1' color='error' />}
                  {row.status === 'REVIEWING_AGAIN' && <Chip label='Chờ review lại' color='primary' />}
                </div>
              ),
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupHomeworkList;

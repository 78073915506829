import { useParams } from 'react-router-dom';
import { StudentPaperResult } from './components';

const StudentPaperResultView = () => {
  const { id, from } = useParams();
  return (
    <>
      <StudentPaperResult paperResultId={+id!} from={from} />
    </>
  );
};

export default StudentPaperResultView;

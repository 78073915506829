import { PostAdd, Check, Close, Money } from '@mui/icons-material';
import { Button, Chip, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { queryClient, registerService } from 'services';
import { RegisterStatusTypes, formatDateTime, formatNumber } from 'utils/common';
import {
  PopupDocShare,
  PopupDocShareHis,
  PopupImportPayment,
  PopupRegisterApprove,
  PopupRegisterCancel,
  PopupRegisterEmail,
  PopupRegisterPromotion,
  RegisterSearch,
} from './components';
import { enqueueSnackbar } from 'notistack';

const RegisterList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['registerService.fetchRegisters', dataSearch],
    () => registerService.fetchRegisters(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0, emails = '' } = data ?? {};

  const [openUpdateApprove, setOpenUpdateApprove] = useState(false);
  const [openUpdateCancel, setOpenUpdateCancel] = useState(false);
  const [openImportPayment, setOpenImportPayment] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openShareVideo, setOpenShareVideo] = useState(false);
  const [openShareVideoHis, setOpenShareVideoHis] = useState(false);
  const [openPromotion, setOpenPromotion] = useState(false);
  const [itemChoice, setItemChoice] = useState<Register>();

  const { mutate: addZalo } = useMutation(registerService.addZalo, {
    onSuccess: () => {
      enqueueSnackbar('Xác nhận join Zalo thành công');
      queryClient.invalidateQueries(['registerService.fetchRegisters']);
    },
  });

  const handleClickSubmitJoinZalo = (registerId: number) => {
    addZalo({
      id: registerId,
    });
  };

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách đăng ký
      </Typography>

      <RegisterSearch onChange={onSearchChange} />
      <div className='mt-6 flex justify-end space-x-3'>
        <Button onClick={() => setOpenImportPayment(true)}>Import Sao kê</Button>
        <Button color='success' onClick={() => setOpenEmail(true)}>
          Xuất danh sách email
        </Button>
        <Button color='info' onClick={() => setOpenShareVideo(true)}>
          Xác nhận share video
        </Button>
        <Button color='secondary' onClick={() => setOpenShareVideoHis(true)}>
          Lịch sử share video
        </Button>
      </div>
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 96}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'code',
            headerName: 'Thông tin đăng ký',
            flex: 1,
            minWidth: 240,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.code} <br /> {formatDateTime(row.registerAt)} <br />
                {(row.status === 'SUCCESS' || row.status === 'LEARNING' || row.status === 'FINISH') && (
                  <Chip label={RegisterStatusTypes.find((item) => item.value === row.status)?.label} color='success' />
                )}
                {row.status === 'CANCEL' && (
                  <Chip label={RegisterStatusTypes.find((item) => item.value === row.status)?.label} color='error' />
                )}
                {row.status === 'RESERVE' && (
                  <Chip label={RegisterStatusTypes.find((item) => item.value === row.status)?.label} color='primary' />
                )}
                {row.status === 'WAIT_PAYMENT' && (
                  <Chip label={RegisterStatusTypes.find((item) => item.value === row.status)?.label} color='info' />
                )}
              </div>
            ),
          },
          {
            field: 'user.Email',
            headerName: 'Thông tin học viên',
            flex: 1,
            minWidth: 290,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.user.fullName}
                <br />
                {row.user.email}
                <br />
                {row.user.phone}
              </div>
            ),
          },
          {
            field: 'classroom.code',
            headerName: 'Lớp đăng ký',
            flex: 1,
            minWidth: 220,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.classroom?.code}
                <br />
                {row.course?.name}
              </div>
            ),
          },
          {
            field: 'classroom.fee',
            headerName: 'Học phí (VND)',
            flex: 1,
            minWidth: 170,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.promotionId && (
                  <>
                    Gốc: {formatNumber(row.orgFee)}
                    <br />
                    Giảm: {formatNumber(row.discountAmount)}
                    <br />
                    Còn lại: {formatNumber(row.fee)}
                  </>
                )}
                {!row.promotionId && <>{formatNumber(row.fee)}</>}
              </div>
            ),
          },
          {
            field: 'zaloAdded',
            headerName: 'Join Zalo',
            sortable: false,
            minWidth: 80,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                {row.zaloAdded === 1 && (
                  <Tooltip title='Đã join Zalo'>
                    <Chip label={<Check />} color='success' variant='filled' />
                  </Tooltip>
                )}
                {(row.zaloAdded === null || row.zaloAdded === 0) && (
                  <Tooltip title='Chưa join zalo'>
                    <Chip label={<Close />} />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            field: 'sharedVideo',
            headerName: 'Video',
            sortable: false,
            minWidth: 80,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                {row.sharedVideo === 1 && (
                  <Tooltip title='Đã được chia sẻ video'>
                    <Chip label={<Check />} color='success' variant='filled' />
                  </Tooltip>
                )}
                {(row.sharedVideo === null || row.sharedVideo === 0) && (
                  <Tooltip title='Chưa được chia sẻ video'>
                    <Chip label={<Close />} />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 170,
            renderCell: ({ row }) => (
              <div className='flex space-x-1'>
                {row.status === 'WAIT_PAYMENT' && (
                  <Tooltip title='Xác nhận đăng ký'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenUpdateApprove(true);
                        setItemChoice(row);
                      }}
                    >
                      <Check />
                    </Button>
                  </Tooltip>
                )}
                {row.status === 'WAIT_PAYMENT' && (
                  <Tooltip title='Huỷ đăng ký'>
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        setOpenUpdateCancel(true);
                        setItemChoice(row);
                      }}
                    >
                      <Close />
                    </Button>
                  </Tooltip>
                )}
                {row.status === 'WAIT_PAYMENT' && (
                  <Tooltip title='Cập nhật khuyến mại'>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setOpenPromotion(true);
                        setItemChoice(row);
                      }}
                    >
                      <Money />
                    </Button>
                  </Tooltip>
                )}
                {row.status !== 'WAIT_PAYMENT' && row.status !== 'CANCEL' && row.zaloAdded !== 1 && (
                  <Tooltip title='Xác nhận join nhóm zalo'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        handleClickSubmitJoinZalo(row.id);
                      }}
                    >
                      <PostAdd />
                    </Button>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]}
      />
      <Dialog open={openUpdateApprove}>
        <PopupRegisterApprove item={itemChoice!} onClose={() => setOpenUpdateApprove(false)} />
      </Dialog>
      <Dialog open={openUpdateCancel}>
        <PopupRegisterCancel item={itemChoice!} onClose={() => setOpenUpdateCancel(false)} />
      </Dialog>
      <Dialog open={openPromotion}>
        <PopupRegisterPromotion item={itemChoice!} onClose={() => setOpenPromotion(false)} />
      </Dialog>
      <Dialog open={openImportPayment}>
        <PopupImportPayment onClose={() => setOpenImportPayment(false)} />
      </Dialog>
      <Dialog open={openEmail}>
        <PopupRegisterEmail emails={emails} onClose={() => setOpenEmail(false)} />
      </Dialog>
      <Dialog open={openShareVideo}>
        <PopupDocShare onClose={() => setOpenShareVideo(false)} />
      </Dialog>
      <Dialog maxWidth='lg' open={openShareVideoHis}>
        <PopupDocShareHis onClose={() => setOpenShareVideoHis(false)} />
      </Dialog>
    </Container>
  );
};

export default RegisterList;
